import React from "react"
import Button from "../Button"
import { Link } from "gatsby"
import Styled from "../Thankyou/styled.module.css"
import Divider from "../../assets/img/careers/divider1.svg"
function Thankyou({ obj }) {
  return (
    <section className={Styled.section} id="section">
      <div className={Styled.hero}>
        <h1 className={`${Styled.heroTitle}`}>Thanks for contacting us</h1>
        <h2
          className={Styled.heroSubtitle}
          dangerouslySetInnerHTML={{ __html: obj.title }}
        ></h2>
        <div className={Styled.heroButtonGroup}>
          <Link to="/">
            <button className={Styled.btnPrimary}>Go to homepage</button>
          </Link>
        </div>
      </div>
      <img className={Styled.divider} alt="Divider" src={Divider}></img>
    </section>
  )
}

export default Thankyou
